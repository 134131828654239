<script>
import { defineComponent, inject } from "vue";
import NavMainSearch from "@kit/components/NavMainSearch.vue";
import { searchWidgetMain, navbar } from "@project/themes";
import NavBarBase from "@kit/components/NavBarBase.vue";

export default defineComponent({
  name: "NavBar",
  setup() {
    const themer = inject("themer")

    //ALEX-RESKIN
    const navbarTheme = themer(navbar)

    //return { searchWidgetMain, navbar }; //ALEX-RESKIN
    return { searchWidgetMain, navbarTheme }
  },
  components: {
    NavMainSearch,
    NavBarBase,
  },
});
</script>

<style>
.seven-tribes-nav-outer {
  padding-right: 30px;
}

.seven-tribes-nav-item,
.seven-tribes-nav-item > a {
  font-family: Oswald, sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #555555;
}
.seven-tribes-nav-item:hover,
.seven-tribes-nav-item.nav-none:hover > a {
  transition: color 0.3s linear;
}
.seven-tribes-nav-item:hover,
.seven-tribes-nav-item.nav-none:hover > a {
  color: var(--light-blue);
}

.seven-tribes-logo-wrapper {
  padding: 15px;
}

.seven-tribes-nav-item {
  padding: 20px;
  cursor: pointer;
}

.seven-tribes-nav-item:hover {
  background: #ffffff;
}

.seven-tribes-nav-subitems {
  background-color: var(--dark-text);
}

.seven-tribes-nav-subitems > a {
  font-family: Oswald, sans-serif;
  position: relative;
  background-color: var(--dark-text);
  color: #e2e2e2;
  padding: 10px 20px 10px 20px;
  overflow: hidden;
  transition: background-color 0.3s linear;
}
.seven-tribes-nav-subitems > a:hover {
  background-color: var(--darker-blue);
}
.seven-tribes-nav-subitems > a:visited {
  font-weight: normal;
}

/* Search styles */

.seven-tribes-nav-main-search-searchoption {
  z-index:1;
  padding-left:0px !important; 
  padding-right:0px !important; 
  width:30px !important;
}

.seven-tribes-search-modal-window {
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1000;
  min-height: 335px;
  padding-top: 107px;
  padding-bottom: 50px;
  background-color: rgba(6, 42, 70, 0.92);
  background-image: linear-gradient(257deg, rgba(19, 95, 129, 0.68), rgba(6, 42, 70, 0.73));
  opacity: 1;
}

input.nav-mobile-search-input:focus, input.nav-main-search-input:focus, input.nav-main-search-nonmodal-input:focus  {
  background-color:#d4e3fa !important;
}

input.nav-mobile-search-input, input.nav-main-search-input, input.nav-main-search-nonmodal-input {
  border-style: none none solid;
  background-color: #f8f8f8;
  font-family: Oswald, sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  padding:10px !important;
}
input.nav-main-search-nonmodal-input {
  border: 1px solid #AAAAAA;
}
@media (max-width : 960px) {
  input.nav-mobile-search-input {
    font-size: 25px;
  }
}

.seven-tribes-search-results-outer {
  margin-bottom:20px;
}

@media (max-width : 960px) {
  .seven-tribes-search-results-outer {
    margin-top:80px;
  }
}

.nav-main-search-ui-container {
  background-color:white;
  border-radius:5px;
}
.nav-mobile-search-ui-container {
  background-color:white;
  border-radius:5px;
}

/*modal styles*/
.seven-tribes-search-modal-search-button {
  border: 2px solid var(--light-blue);
  font-size: 16px;
  width: 200px !important;
}
.seven-tribes-search-results-prevnext-wrapper {
  padding:0px !important;
}

/*nonmodal widget*/
.seven-tribes-search-modal-search-nonmodal-button {
  width: 50px !important;
}
.seven-tribes-search-nonmodal-headline {
  font-family: Oswald, sans-serif;
  font-weight:400;
  color: var(--dark-text);
}

/*styles for the mobile side-nav drawer ui*/
.seven-tribes-mobile-search-button {
  width: 50% !important;
}
.seven-tribes-mobile-search-button svg {
  transform: rotate(45deg);
}
.seven-tribes-mobile-search-headline {
  font-family: Oswald, sans-serif;
  font-size: 36px;
  line-height: 36px;
  font-weight: 400;
  color:white; 
}
.seven-tribes-mobile-search-wrapper {
  padding-top:20px;
  padding-right:20px;
}
.seven-tribes-mobile-search-completion-wrapper, .seven-tribes-main-search-completion-wrapper  {
  color:black;
  font-family: Oswald, sans-serif;
  font-size: 20px;
}
.seven-tribes-mobile-completion-result {
  padding:7px;
}
.seven-tribes-main-completion-result {
  padding:7px;
  font-size: 18px;
}
.seven-tribes-mobile-completion-result:hover, .seven-tribes-main-completion-result:hover  {
  background-color:#EEEEEE;
}




</style>

<template>
  <NavBarBase :theme="navbarTheme">
    <template v-slot:nav-items>
      <div class="sb sb-h seven-tribes-nav-item nav-none sb-align-cv navbar-item">
        <router-link class="sb" to="/">Coming Soon</router-link><i></i>
      </div>

      <!--
      <div class="sb sb-h seven-tribes-nav-item nav-none sb-align-cv navbar-item">
        <router-link class="sb" to="/">Home</router-link><i></i>
      </div>
      <div class="sb sb-h seven-tribes-nav-item nav-none sb-align-cv navbar-item">
        <router-link class="sb" to="/plans-and-pricing">Plans</router-link><i></i>
      </div>
      <div class="sb sb-h seven-tribes-nav-item nav-dropdown sb-align-cv navbar-item navbar-dropdown">
        Cellular
        <div class="sb sb-v navbar-subitems seven-tribes-nav-subitems seven-tribes-nav-item-text">
          <router-link to="/cellular-business" style="white-space: nowrap">
            Cellular Business
          </router-link>
          <router-link to="/cellular-residential" style="white-space: nowrap">
            Cellular Residential
          </router-link>
        </div>
      </div>
      <div class="sb sb-h seven-tribes-nav-item nav-dropdown sb-align-cv navbar-item navbar-dropdown">
        Broadband
        <div class="sb sb-v navbar-subitems seven-tribes-nav-subitems seven-tribes-nav-item-text">
          <router-link to="/business-broadband" style="white-space: nowrap">
            Business Broadband
          </router-link>
          <router-link to="/residential-broadband" style="white-space: nowrap">
            Residential Broadband
          </router-link>
          <router-link to="/fiber-buildout" style="white-space: nowrap">
            Fiber Buildout
          </router-link>
        </div>
      </div>
      <div class="sb sb-h seven-tribes-nav-item nav-none sb-align-cv navbar-item">
        <router-link class="sb" to="/contact">Contact</router-link><i></i>
      </div>
      <div class="sb sb-h seven-tribes-nav-item nav-none sb-align-cv navbar-item">
        About
      </div>
      -->
      <NavMainSearch :theme="searchWidgetMain" />
    </template>
  </NavBarBase>
</template>
