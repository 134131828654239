export const routes = [
  {
    name: 'root',
    path: '/',
    component: () => import(/* webpackChunkName: "root" */'@pages/Home.vue'),
  },
  {
    name: 'cellular-business',
    path: '/cellular-business',
    component: () => import(/* webpackChunkName: "cellular-business" */'@pages/Section.vue'),
  },
  {
    name: 'cellular-residential',
    path: '/cellular-residential',
    component: () => import(/* webpackChunkName: "cellular-residential" */'@pages/Section.vue'),
  },
  {
    name: 'business-broadband',
    path: '/business-broadband',
    component: () => import(/* webpackChunkName: "business-broadband" */'@pages/Section.vue'),
  },
  {
    name: 'residential-broadband',
    path: '/residential-broadband',
    component: () => import(/* webpackChunkName: "residential-broadband" */'@pages/Section.vue'),
  },
  {
    name: 'fiber-buildout',
    path: '/fiber-buildout',
    component: () => import(/* webpackChunkName: "fiber-buildout" */'@pages/Section.vue'),
  },
  {
    name: 'plans-and-pricing',
    path: '/plans-and-pricing',
    component: () => import(/* webpackChunkName: "fiber-buildout" */'@pages/Plans.vue'),
  },
  {
    name: 'contact',
    path: '/contact',
    component: () => import(/* webpackChunkName: "fiber-buildout" */'@pages/Contact.vue'),
  },
  {
    name: 'search',
    path: '/search/:searchTerm/:page',
    component: () => import(/* webpackChunkName: "fiber-buildout" */'@pages/Search.vue'),
  }
]