import { home } from '@project/api/home/v1'
import { section } from '@project/api/sections/v1'
import { footer } from '@project/api/footer/v1'
import { planList } from '@project/api/plan-list/v1'
import { contact } from '@project/api/contact/v1'
import { search } from '@project/api/search/v1'


export default {
  async home() {
    return await home()
  },
  async section(params) {
    return await section(params.page)
  },
  async footer() {
    return await footer()
  },
  async planList() {
    return await planList()
  },
  async contact() {
    return await contact()
  },
  async search() {
    return await search()
  }
}

