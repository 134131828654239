<script>
import { defineComponent } from "vue";
import SidebarRouteLink from "@kit/components/SidebarRouteLink.vue";
import NavMainSearch from "@kit/components/NavMainSearch.vue";
import SideNavDrawerBase from "@kit/components/SideNavDrawerBase.vue";
import { searchWidgetMobile, sideDrawer } from "@project/themes";
import { getCurrentYear } from "@kit/utils/Formats"

export default defineComponent({
  name: "SizeNavDrawer",
  setup() {
    return { searchWidgetMobile, sideDrawer, getCurrentYear };
  },
  components: {
    SideNavDrawerBase,
    SidebarRouteLink,
    NavMainSearch,
  },
});
</script>

<style>
.side-nav-1 {
  font-size: 25px;
  color: white;
}

.side-nav-1 > a {
  color: var(--lighter-blue);
  transition: 0.3s color linear;
}
.side-nav-1 > a:hover {
  color: var(--light-blue);
}
.side-nav-1 > a:visited {
  color: var(#eeeeee);
}

@media (max-width: 960px) {
}

.seven-tribes-side-nav-drawer {
  background-color: rgba(6, 42, 70, 0.92);
  background-image: linear-gradient(
    257deg,
    rgba(19, 95, 129, 0.68),
    rgba(6, 42, 70, 0.73)
  );
  opacity: 1;
}
</style>

<template>
  <SideNavDrawerBase :theme="sideDrawer">
    <template #nav-items="{ active }">
      <div class="sb sb-v side-nav-1">
        <SidebarRouteLink to="/">Coming Soon</SidebarRouteLink>
      </div>
      <!--
      <div class="sb sb-v side-nav-1">
        <SidebarRouteLink to="/">Home</SidebarRouteLink>
      </div>
      <div class="sb sb-v side-nav-1">
        <SidebarRouteLink to="/plans-and-pricing">Plans</SidebarRouteLink>
      </div>
      <div class="sb sb-v side-nav-1">
        <SidebarRouteLink to="/cellular-business">Cellular Business</SidebarRouteLink>
      </div>
      <div class="sb sb-v side-nav-1">
        <SidebarRouteLink to="/cellular-residential"
          >Cellular Residential</SidebarRouteLink
        >
      </div>
      <div class="sb sb-v side-nav-1">
        <SidebarRouteLink to="/business-broadband">Business Broadband</SidebarRouteLink>
      </div>
      <div class="sb sb-v side-nav-1">
        <SidebarRouteLink to="/residential-broadband"
          >Residential Broadband</SidebarRouteLink
        >
      </div>
      <div class="sb sb-v side-nav-1">
        <SidebarRouteLink to="/fiber-buildout">Fiber Buildout</SidebarRouteLink>
      </div>
      <div class="sb sb-v side-nav-1">
        <SidebarRouteLink to="/contact">Contact</SidebarRouteLink>
      </div>
      <div class="sb sb-v side-nav-1">
        <SidebarRouteLink to="/about">About</SidebarRouteLink>
      </div>
      <NavMainSearch :theme="searchWidgetMobile" :active="active" />
      -->
      <div class="sb sb-v side-nav-1">
        © {{ getCurrentYear() }} 7-Tribes
      </div>
    </template>
  </SideNavDrawerBase>
</template>
