
import { pathname } from '@kit/utils/Formats'
import "@images/7Tribes_Logo_New_Variations_Normal-Horizontal.png" 

export const navbar = {

  //optional. if it will shrink on scroll
  scrollShrink: true, //false,

  //mergeable classes for the hamburger button
  //hamburgerButtonClasses: "some class",

  //the mergeable classes for the outer fixed wrapper
  outerContainerClassesMobile: "seven-tribes-nav-outer",
  outerContainerClassesDesktop: "seven-tribes-nav-outer",

  logoWrapperClasses:"seven-tribes-logo-wrapper",

  //the logo and aria text
  logoSrc:pathname("@images/7Tribes_Logo_New_Variations_Normal-Horizontal.png"),
  logoAriaAlt:"7-tribes navigation logo",

  //required
  height: "90px",
  shrinkHeight: "50px",

  padding: "15px",
  shrinkPadding: "5px",

  //optional. The top gap
  topGap: "15px"
    
}

export const sideDrawer = {
  topNavHeight:"90px",
  outerClasses:"seven-tribes-side-nav-drawer"
}