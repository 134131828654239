import { ButtonAlignCenter, ButtonFlashRadial, ButtonIconPositionHugLeft, ButtonIconPositionShiftLeft, ButtonIconActiveHeight } from '@kit/components/IconButton.vue'
import colors from '@project/theme-colors'

  export const baseButton = {
    foreground: {
      active:   "#FFFFFF",
      inactive: "#FFFFFF",
      flash:    "#FFFFFF",
      loading:  "#FFFFFF" 
    },
    iconPosition: ButtonIconPositionHugLeft,
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "15px"
    },

    align: ButtonAlignCenter,
    flash: ButtonFlashRadial,
    allowPropagate: false,
    loader: {
      fill:"#CCCCCC", 
      wheel: {
        gif:"Spinner-0.4s-310px-ffffff-transparent",
        spinnerStyle:"width:120%; height:120%; top:-10%; left:-10%; background-size:contain; background-position:center; background-repeat:no-repeat; position:absolute;"
      }
    }
  }


  export const blueAgainstLight = {
    ...baseButton,
    background: {
      active:       colors["darker-blue"],
      inactive:     "#CCCCCC",
      flash:        colors["light-blue"],
      loading:      "#CCCCCC",
      hover:        colors["main-blue"],   
      padding:      "20px", 
      borderRadius: "0px"    
    },
  }

  export const blueAgainstDark = {
    ...baseButton,
    background: {
      active:       colors["main-blue"],
      inactive:     "#CCCCCC",
      flash:        colors["lighter-blue"],
      loading:      "#CCCCCC",
      hover:        colors["light-blue"],   
      padding:      "20px", 
      borderRadius: "0px"    
    },
  }

  export const magnifyingGlassButton = { 
    ...blueAgainstLight, 
    icon:"rounded-magnifying-glass",
    iconStyle:"transform:rotate(45deg)",
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "100%"
    },
    foreground: "#FFFFFF",
    background: {  
      ...blueAgainstLight.background,
      padding:      "5px", 
      borderRadius: "0px"    
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '30px'
    },
  }

  export const footerButton = {...blueAgainstLight, 
    iconFromProject:true, 
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "20px"
    }
  }




  