
//Here's the api connector to communicate with the wordpress
//https://localhost/cms-service/index.php/wp-json/v1/section/id/132


import { get } from '@kit/utils/APIV1.js'

export const section = async(which) => {
  if(which == "cellular-business") {
    return get('section/id/132')
  } else 
  if(which == "cellular-residential") {
    return get('section/id/156')
  } else 
  if(which == "business-broadband") {
    return get('section/id/158')
  } else 
  if(which == "residential-broadband") {
    return get('section/id/160')
  } else 
  if(which == "fiber-buildout") {
    return get('section/id/163')
  }
}