
<script>
import { defineComponent, inject, computed } from 'vue'
import IconButton from '@kit/components/IconButton.vue'
import { blueAgainstLight } from '@project/themes'
import { inBrowser } from '@kit/utils/EnvironmentHelper'
import { getCurrentYear } from "@kit/utils/Formats"
import "@images/7Tribes_Logo_New_Variations_Default-Large.png"

export default defineComponent({
  name: "Footer",
  setup() {
    const smoothScroll = inject('smoothScroll')
    const hydrate = inject("hydrate")

    const { prefetch } = hydrate({
      prefetch:["footer"],
      async mounted() {
        //await sleep(500)
        // scroller.register(".tile-trigger", { "type":"scrollDelayMultiple", "delay":400, "th_in":0.2, "th_out":0.5 })
        // scroller.register(".plan-list", { "type":"scrollDelayMultiple", "delta":"40px", "delay":400, "th_in":0.2, "th_out":0.2, "down":[1,0], "up":[-1,0] })
      }
    })

    const footerData = computed(() => {
      return prefetch.value ? prefetch.value.footer : null
    })

    const footerButtonOnClick = () => {
      if(inBrowser) {
        const topNav = document.getElementById("top-of-page")
        smoothScroll({ scrollTo:topNav })
      }
    }
    return { blueAgainstLight, footerButtonOnClick, footerData, getCurrentYear }
  },
  components: {
    IconButton
  }
});
</script>

<style>
.background {
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.82)), to(hsla(0, 0%, 100%, 0.82))), url(@images/white-waves.jpg);
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.82), hsla(0, 0%, 100%, 0.82)), url(@images/white-waves.jpg);
  background-position: 0px 0px, 50% 100%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}
.width33Pct {
  width:33% !important;
}
.footer-button {
  font-size:20px;
  width:60px !important;
}

@media (max-width : 600px) {
  .width33Pct {
    width:100% !important;
  }
}
.height150Px {
  height:150px !important;
}

</style>

<template>

  <footer v-if="footerData" class="sb sb-h sb-v background" style="margin-top:50px;">
    <div class="sb sb-h sb-explicit sb-alt2 sb-align-ch width33Pct" style="padding-top:60px; padding-bottom:60px;">
      <div class="sb sb-explicit" style="width:100px;">
        <img src="@images/7Tribes_Logo_New_Variations_Default-Large.png" style="width:100%; height:auto; object-fit:cover">
      </div>
    </div>
    <div class="sb sb-h sb-ltm-h2v sb-uniform-2">
      <div class="sb sb-v sb-alt2 sb-align-ch" style="padding-top:60px; padding-bottom:60px;">
        <div class="sb sb-v" v-html="footerData.tile_one"></div>
      </div>
      <div class="sb sb-v sb-alt2 sb-align-ch" style="padding-top:60px; padding-bottom:60px;">
        <div class="sb sb-v" v-html="footerData.tile_two"></div>
      </div>
      <!-- <div class="sb sb-v sb-alt2 sb-align-ch" style="padding-top:60px; padding-bottom:60px;">
        <div class="sb sb-v">
          <h4 class="sb footer-headline">Quick Links</h4>
          <span class="sb footer-text">Request Call Back</span>
          <span class="sb footer-text">Support</span>
        </div>
      </div> -->
    </div>
    <div class="sb sb-h sb-content-space-around sb-alt2 sb-align-cv" style="padding-top:60px; padding-bottom:60px;">
      <div class="sb sb-text" style="text-align:center;">
        © {{getCurrentYear()}} {{ footerData.company_name }} | <a href="/privacy">Privacy Policy</a>
      </div>
      <IconButton class="sb footer-button" @buttonClick="footerButtonOnClick" icon="plain-arrows_up-rd-2" :theme="blueAgainstLight" text=""/>   
    </div>
  </footer>


</template>