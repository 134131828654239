import { ButtonIconActiveHeight } from '@kit/components/IconButton.vue'
import { blueAgainstDark, blueAgainstLight, magnifyingGlassButton } from '@project/theme-buttons'
import { ModalPositionCenter } from '@kit/components/Modal.vue'



  export const searchResultsModal = {

    suspendScrolling: true,
    
    backgroundStyle: "opacity:0.8; background-color:#FFFFFF;",
    
    windowStyles: "background:#FFFFFF; box-shadow:6px 6px 5px #999999; border-radius:10px; border-left:2px solid #DDDDDD; border-top:2px solid #DDDDDD;",

    vw: 80,
    vh: 70,
    mvw: 80,
    mvh: 80,
    position: ModalPositionCenter,

    closeButton: {
      ...blueAgainstLight, 
      icon:"rounded-close-x_50", 
      iconDimension: {
        style: ButtonIconActiveHeight,
        active: "20px"
      },
      background: {
        ...blueAgainstLight.background,
        borderRadius: "50%"   
      },
    }
  }

  export const searchWidgetModal = {

    suspendScrolling: false,
    windowClasses: "seven-tribes-search-modal-window",
    windowStyles: "",
    windowAutoPosition: false,

    closeButton: {
      ...blueAgainstDark, 
      icon:"squared-close-x_50", 
      iconDimension: {
        style: ButtonIconActiveHeight,
        active: "20px"
      },
      background: {
        ...blueAgainstDark.background,
        borderRadius: "50%"   
      },
    }
  }

  export const searchResults = {

    //show the thumbnails for the search results?
    showThumbnails: false,

    //The mergeable classes for the outer wrapper. See mergeClassesTheme 
    outerClasses: "seven-tribes-search-results-outer",

    //The mergable classes for the button container
    buttonClasses: "seven-tribes-search-results-prevnext-wrapper",

    //the mergable classes for the results-block
    resultsBlockClasses: "seven-tribes-results-block",

    //the mergeable clases for each the result element
    resultElementClasses: "seven-tribes-search-result",

    //The mergeable classes for the loading wrapper. See mergeClassesTheme 
    loadingClasses: "seven-tribes-search-loading",

    //the theme for the buttons, and the icon for each
    prevNextButton: blueAgainstLight,
    prevIcon:"rounded-single-chevron-left",
    nextIcon:"rounded-single-chevron-right",
  }

  //The theme for the search results component in the modal.
  export const searchResultsInModal = {
    ...searchResults,
    outerClasses: "sb-greedy seven-tribes-search-results-outer",
  }


  export const searchWidgetMain = {
  
    //The height of the widget
    mainWidgetHeight:60,

    //The height of the drop-shadow at the bottom. 
    shadowAdjustment:5,

    //Show the thumbnails
    showThumbnails: false,
  
    //the options for the navigation item in the top menu.
    navComponent: {

      outerClasses: "seven-tribes-nav-main-search-searchoption",
      
      button: { ...blueAgainstLight, 
        icon:"rounded-magnifying-glass-negative",
        iconDimension: {
          style: ButtonIconActiveHeight,
          active: "100%"
        },
        foreground: "#FFFFFF",
        background: {  
          ...blueAgainstLight.background,
          padding:      "0px", 
          borderRadius: "0px"    
        },
        iconDimension: {
          style: ButtonIconActiveHeight,
          active: '30px'
        },
      },
    },

    //the place to put the classes and styles for the search widget modal
    searchComponent: {
      
      modalMode:true,

      //modalMode:true
      //The two headlines in the search modal window
      topHeadline: "Search",
      mainHeadline: "Search our website",

      //for the non-modal style
      //mainHeadlineClasses: "seven-tribes-search-nonmodal-headline",

      //the modal theme for the search widget
      modal: searchWidgetModal,

      completionWrapperClasses:"seven-tribes-main-search-completion-wrapper",

      completionResultClasses:"seven-tribes-main-completion-result",

      //the search button 
      //modal style
      searchButton: {...blueAgainstLight, text:"Search", outerClasses:"seven-tribes-search-modal-search-button" },
      
      //non-modal style
      //searchButton: { ...magnifyingGlassButton, outerClasses:"seven-tribes-search-modal-search-nonmodal-button" },
        
    },

    //The options for the search-results.
    resultsComponent: {

      //the route. Used for if you want the results to appear on another page
      route: "/search",

      //the theme for the search
      searchResults: searchResults,

      //the modal theme, for use in the event that route is null
      modal: searchResultsModal
    }



  }
 

   
  export const searchWidgetMobile = {

    ...searchWidgetMain,

    isMobile: true,

    //the options for the navigation item in the top menu.
    navComponent: {
      button: { ...blueAgainstDark, 
        icon:"rounded-magnifying-glass",
        foreground: "#FFFFFF",
        background: {  
          ...blueAgainstDark.background,
          padding:      "20px", 
          borderRadius: "5px"    
        },
        iconDimension: {
          style: ButtonIconActiveHeight,
          active: '30px'
        },
        outerClasses:"sb-explicit seven-tribes-mobile-search-button"
      },

      //the mergeable classes for the outer-wrapper
      outerClasses: "seven-tribes-mobile-search-wrapper",

      completionWrapperClasses:"seven-tribes-mobile-search-completion-wrapper",

      completionResultClasses:"seven-tribes-mobile-completion-result",

      completionUnderPosition: false,

      //the mergeable classes for the headline
      headlineClasses: "seven-tribes-mobile-search-headline",
    
      headline:"Search 7-Tribes"

    },
    

  } 
  