
const colors = {
  "main-blue": "#0F8BC7",
  "darker-blue": "#335a8a",
  "dark-text": "#161a25",
  "med-text": "#525b73",
  "light-text": "#FFFFFF",
  "light-blue": "#34b8f7",
  "lighter-blue": "#99e0ff",
  "midpage-background": "#f7f7f7",
  "light-grey-text": "#9098a1"
}

export default colors